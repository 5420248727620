import React from 'react';
import ProjectsAdmin from '../components/ProjectsAdmin';

export default function AdminProjects() {
  return (
    <div>
      <h2>Admin Portal</h2>
      <ProjectsAdmin />
    </div>
  );
}
